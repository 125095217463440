import { Box, Button, Heading, HStack, Icon, useAnalytics, VStack } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../../../../components/FixedFooterLayout';
import usePositionInfoMutations from '../../../../../hooks/usePositionInfoMutations';
import React, { useEffect } from 'react';
import { ExperienceSummaryCard } from './ExperienceSummaryCard';
import { useExperienceSummaryViewQueries } from './hooks';
import useDialog from '../../../../../hooks/useDialog';
import { AddExperienceModal } from '../workExperienceModal/AddExperienceModal';

export const ExperienceSummaryView = () => {
  const analytics = useAnalytics();
  const { closeDialog, open, openDialog } = useDialog();
  const {
    currentUser,
    workHistories,
    isLoading: queryIsLoading,
  } = useExperienceSummaryViewQueries();

  const professionalPositions = currentUser?.professional?.professional_positions || [];

  const { nextStep, prevStep, isLoading: mutationIsLoading } = usePositionInfoMutations();

  const disableButtons = queryIsLoading || mutationIsLoading;

  const handleBackClick = () => {
    prevStep();
  };

  const handleContinueClick = () => {
    nextStep();
  };

  const canContinue = !!workHistories?.length;

  useEffect(() => {
    analytics.track('Product Application Experience Summary  - Viewed', {
      account_id: currentUser?.id,
      context: 'Product Application Experience Summary',
      subcontext: null,
      action: 'Viewed',
    });
  }, []);

  return (
    <FixedFooterLayout
      footerContent={
        <HStack>
          <Box mr={0.5} width="100%">
            <Button
              onClick={handleBackClick}
              variant="outlined"
              fullWidth
              disabled={disableButtons}
            >
              Back
            </Button>
          </Box>
          <Box ml={0.5} width="100%">
            <Button
              onClick={handleContinueClick}
              variant="contained"
              fullWidth
              disabled={disableButtons || !canContinue}
            >
              Continue
            </Button>
          </Box>
        </HStack>
      }
    >
      <VStack py={3} px={2} gap={2}>
        <Box mb={2}>
          <Heading size="s" testId="experience-summary-title">
            Complete your clinical experience to maximize earnings (optional)
          </Heading>
        </Box>
        {professionalPositions?.map((professionalPosition) => (
          <ExperienceSummaryCard
            key={professionalPosition.position.id}
            position={professionalPosition.position}
            workHistories={workHistories?.filter(
              (workHistory) => workHistory.position_id === professionalPosition.position.id,
            )}
          />
        ))}
        <Button
          variant="text"
          fullWidth
          startIcon={
            <Icon name="plus" iconSize="18px" variant="custom" color="state.teal.primary" />
          }
          onClick={() => {
            openDialog();
          }}
          testId="experience-summary-add-experience-button"
        >
          Add experience
        </Button>
      </VStack>
      <AddExperienceModal open={open} onClose={closeDialog} />
    </FixedFooterLayout>
  );
};
